import {
    useTranslate,
    IResourceComponentsProps,
    useList,
  } from "@pankod/refine-core";
  import {
    List,
    Table,
    TextField,
    useTable,
    getDefaultSortOrder,
    DateField,
    Space,
    EditButton,
    DeleteButton,
    ShowButton,

  } from "@pankod/refine-antd";

import { formatDateTime } from "utils/dates";

import { Access } from "interfaces/access";
import { House } from "interfaces/house";
  
  export const AccessList: React.FC<IResourceComponentsProps> = () => {
    const token = localStorage.getItem("refine-auth");
    let initialIsSuperuser = false;

  if (token) {
    const payload = JSON.parse(atob(token.split(".")[1]));
    
    initialIsSuperuser = payload.is_superuser;
  }
    const t = useTranslate();
  
    const { tableProps, sorter } = useTable<Access>({
      initialSorter: [
        {
          field: "name",
          order: "desc",
        },
      ],
    });

    const housesQueryResult = useList<House>({
      resource: "houses",
    });

    // Get House Address
    const getHouseAddress = (id: string): string => {
      if (housesQueryResult.data?.data && housesQueryResult.data.data.length > 0) {
        const data = housesQueryResult.data.data;
        for (let x: number = 0; x < data.length; x++) {
          var house: House = data[x];
          if (house.ID === id) {
            return house.Address;
          }
        }
      }
      return "";
    } 
  
    
    return (
      <List>
        <Table {...tableProps} rowKey="id">
          {/* <Table.Column
            dataIndex="ID"
            key="id"
            title="ID"
            render={(value) => <TextField value={value} />}
            // defaultSortOrder={getDefaultSortOrder("id", sorter)}
            // sorter
          /> */}
          <Table.Column
            dataIndex="car_plate"
            key="car_plate"
            title={"Car Plate"}
            render={(value) => <TextField value={value} />}
            // defaultSortOrder={getDefaultSortOrder("car_plate", sorter)}
            // sorter
          />
          <Table.Column
            dataIndex="person_name"
            key="person_name"
            title={"Person Name"}
            render={(value) => <TextField value={value} />}
            // defaultSortOrder={getDefaultSortOrder("car_plate", sorter)}
            // sorter
          />
          {/* <Table.Column
            dataIndex="house_id"
            key="houseID"
            title={"House ID"}
            render={(value) => <TextField value={value} />}
            // defaultSortOrder={getDefaultSortOrder("houseid", sorter)}
            // sorter
          /> */}
          <Table.Column
            dataIndex="house_id"
            key="houseAddress"
            title={"House Address"}
            render={(value) => <TextField value={getHouseAddress(value)} />}
            // defaultSortOrder={getDefaultSortOrder("houseid", sorter)}
            // sorter
          />
          <Table.Column
            dataIndex="valid_from"
            key="valid_from"
            title={"Valid From"}
            // render={(value) => <TextField value={value} />}
            // render={(value) => <DateField value={value} format="LLL" />}
            // render={(value) => <TextField value={new Date(value * 1000).toUTCString()} />}
            render={(value) => {
              const formattedDate = formatDateTime(value);
              return <TextField value={formattedDate} />;
            }}
            // defaultSortOrder={getDefaultSortOrder("valid_from", sorter)}
            // sorter
          />
          <Table.Column
            dataIndex="valid_until"
            key="valid_until"
            title={"Valid Until"}
            // render={(value) => <TextField value={value} />}
            // render={(value) => <DateField value={value} format="LLL" />}
            render={(value) => {
              if (value === 0 || value === undefined) {
                return <TextField value="Never" />;
              }
              const formattedDate = formatDateTime(value);
              return <TextField value={formattedDate} />;
            }}
            // defaultSortOrder={getDefaultSortOrder("houseid", sorter)}
            // sorter
          />
          <Table.Column
            dataIndex="UpdatedAt"
            key="updatedAt"
            title={"UpdatedAt"}
            // render={(value) => <DateField value={value} format="LLL" />}
            render={(value) => {
              const formattedDate = formatDateTime(value);
              return <TextField value={formattedDate} />;
            }}
            defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="CreatedAt"
            key="createdAt"
            title={"CreatedAt"}
            // render={(value) => <DateField value={value} format="LLL" />}
            render={(value) => {
              const formattedDate = formatDateTime(value);
              return <TextField value={formattedDate} />;
            }}
            defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
            sorter
          />
          <Table.Column<Access>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                {/* {initialIsSuperuser && (
                <EditButton hideText size="small" recordItemId={record.ID} />
                )} */}
                {/* <ShowButton hideText size="small" recordItemId={record.ID} /> */}
                <DeleteButton hideText size="small" recordItemId={record.ID} />
              </Space>
            )}
          />
        </Table>
      </List>
    );
  };
  