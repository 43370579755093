import { AuthProvider } from "@pankod/refine-core";
import { API_URL } from "const";

export const TOKEN_KEY = "refine-auth";

export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    console.log("API_URL: ", process.env.API_URL)
    const response = await fetch(`${API_URL}/login`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        Email: username,
        Password: password,
      })
    });

    if (response.ok) {
      const token = await response.json()
      localStorage.setItem(TOKEN_KEY, token.token);
      return Promise.resolve();
    }
    return Promise.reject(new Error("Error during login. Please check credentials and try again"));
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      const claims = JSON.parse(atob(token.split('.')[1]));
      const userId = claims.id;
      if (userId) {
        return Promise.resolve({
          id: userId,
        });
      }
    }
    if (!token) {
      return Promise.reject();
    }
  },
};
