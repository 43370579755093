import {
    useTranslate,
    IResourceComponentsProps,
    useList,
  } from "@pankod/refine-core";
  import {
    List,
    Table,
    TextField,
    useTable,
    getDefaultSortOrder,
    DateField,
    Space,
    EditButton,
    DeleteButton,
    ShowButton,
  } from "@pankod/refine-antd";
  
  import { formatDateTime } from "utils/dates";
  
import { CustomerVisit } from "interfaces/customerVisit";
import { table } from "console";
  
  export const CustomerVisitList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
  
    const { tableProps, sorter } = useTable<CustomerVisit>({
      initialSorter: [
        {
          field: "name",
          order: "desc",
        },
      ],
    });
  
    return (
      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="ID"
            key="id"
            title="ID"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("id", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="location_id"
            key="location_id"
            title="Location ID"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("location_id", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="license_plate"
            key="license_plate"
            title={"License Plate"}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="entry_time"
            key="entry_time"
            title={"Entry Time"}
            render={(value) => {
              const formattedDate = formatDateTime(value);
              return <TextField value={formattedDate} />;
            }}
            defaultSortOrder={getDefaultSortOrder("entry_time", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="exit_time"
            key="exit_time"
            title={"Exit Time"}
            render={(value) => {
              if (value === 0) {
                value = "-"
                return <TextField value={value} />;
              }
              const formattedDate = formatDateTime(value);
              return <TextField value={formattedDate} />;
            }}
            defaultSortOrder={getDefaultSortOrder("exit_time", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="vehicle_type"
            key="vehicle_type"
            title={"Vehicle Type"}
            render={(value) => {
              return <TextField value={value} />;
            }}
            defaultSortOrder={getDefaultSortOrder("vehicle_type", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="UpdatedAt"
            key="updatedAt"
            title={t("users.fields.updatedAt")}
            render={(value) => {
              const formattedDate = formatDateTime(value);
              return <TextField value={formattedDate} />;
            }}
            defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="CreatedAt"
            key="createdAt"
            title={t("users.fields.createdAt")}
            render={(value) => {
              const formattedDate = formatDateTime(value);
              return <TextField value={formattedDate} />;
            }}
            defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="payment_intent"
            key="payment_intent.created"
            title={"Payment Intent Created At"}
            render={(value) => {
              if (value.created === 0) {
                return <TextField value={"-"} />
              }
              const formattedDate = formatDateTime(value.created);
              return <TextField value={formattedDate} />;
            }}
          />
          <Table.Column
            dataIndex="payment_intent"
            key="payment_intent.stripe_payment_intent_id"
            title={"Stripe Payment Intent ID"}
            render={(value) => {
              if (value.stripe_payment_intent_id === "") {
                return <TextField value={"-"} />
              }
              return <TextField value={value.stripe_payment_intent_id} />;
            }}
          />
          <Table.Column
            dataIndex="payment_intent"
            key="payment_intent.amount"
            title={"Amount"}
            render={(value) => {
              if (value.amount === 0) {
                return <TextField value={"-"} />
              }
              const convertedAmount = value.amount/100;
              return <TextField value={convertedAmount} />;
            }}
          />
          <Table.Column
            dataIndex="payment_intent"
            key="payment_intent.status"
            title={"Payment Intent Status"}
            render={(value) => {
              if (value.status === "") {
                return <TextField value={"-"} />
              }
              return <TextField value={value.status} />;
            }}
          />
          
          <Table.Column<CustomerVisit>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                {/* <EditButton hideText size="small" recordItemId={record.ID} /> */}
                {/* <ShowButton hideText size="small" recordItemId={record.ID} /> */}
                <DeleteButton hideText size="small" recordItemId={record.ID} />
              </Space>
            )}
          />
        </Table>
      </List>
    );
  };
  