import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { StripeForm } from './StripeForm';
import { set } from 'video.js/dist/types/tech/middleware';

interface StripeElementContainerProps {
    stripePromise: any;
    visitInfo: any;
    prev: () => void;
    setCurrent: (current: number) => void;
    setSuccessfulPaymentInfo: (successfulPaymentInfo: any) => void;
}

export const StripeElementContainer: React.FC<StripeElementContainerProps> = ({
  stripePromise, visitInfo, setCurrent, prev, setSuccessfulPaymentInfo
}) => {
    // Add your component logic here
    const [clientSecret, setClientSecret] = useState<string>('')

    // TODO: Handle error if clientSecret is not set
    useEffect(() => {
      fetch(`http://localhost:8080/api/generate_stripe_secret_for_visit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          license_plate: visitInfo.visit.license_plate,
          visit_id: visitInfo.visit.ID
        }),
      })
      .then((response) => response.json())
      .then((data) => {        
        setClientSecret(data.client_secret)
      })
    }, [])

    const options = {
      clientSecret: clientSecret,
    }

    return (
      <>
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <StripeForm
           clientSecret={clientSecret} 
           setCurrent={setCurrent} 
           visitInfo={visitInfo}
           prev={prev} 
           setSuccessfulPaymentInfo={setSuccessfulPaymentInfo}
          />
        </Elements>
      )
      }
      </>
    );
};