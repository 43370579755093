import axios from "axios";
import { HttpError } from "@pankod/refine-core";
import simpleRestDataProvider from "@pankod/refine-simple-rest";
import { TOKEN_KEY } from "./authProvider";

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      // config.headers.Authorization = `Bearer ${token}`;
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

const dataProvider = (apiURL: string) => simpleRestDataProvider(apiURL, axiosInstance);

export default dataProvider;