import { useState } from "react";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";

import { Create, Form, Input, InputNumber, Select, useSelect, useForm } from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css"

import { Gate } from "interfaces/gate";
import { Location } from "interfaces/location"


export const GateCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { selectProps: locationSelectProps } = useSelect<Location>({
    resource: "locations",
    optionLabel: "Name",
    optionValue: "ID"
  });

  const { formProps, saveButtonProps } = useForm<Gate>();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t("Name")}
          name="Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("Url")}
          name="Url"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("IpV6")}
          name="IpV6"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Location"
          name={"LocationID"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select 
            {...locationSelectProps} 
            showSearch
            filterOption={(input, option) =>
              typeof option?.label === "string" && option?.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label="Gate Type"
          name={"GateType"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Select.Option value="entrance">Entrance</Select.Option>
            <Select.Option value="exit">Exit</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Create>
  );
};
