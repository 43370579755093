import React, { useEffect, useState } from 'react';
import { Card, Input, Form, Button, Space } from 'antd';

interface ReceiptCardProps {
  successfulPaymentInfo: any;
  visitInfo: any;
}

export const ReceiptCard: React.FC<ReceiptCardProps> = ({ successfulPaymentInfo, visitInfo }) => {
  const [email, setEmail] = useState('')
  const [receiptInfo, setReceiptInfo] = useState<any>(null)

  const handleExit = () => {
    window.location.href = 'http://localhost:3000/payments'
  }

  useEffect(() => {
    console.log("RECEIPT: ", successfulPaymentInfo)
    // send info to backend
    fetch(`http://localhost:8080/api/create_receipt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        license_plate: visitInfo.license_plate,
        stripe_payment_intent_id: successfulPaymentInfo.id,
        amount: successfulPaymentInfo.amount,
        description: successfulPaymentInfo.description,
        status: successfulPaymentInfo.status,
        created: successfulPaymentInfo?.created,
        visit_id: visitInfo.visit.ID,
      }),
    }).then((response) => response.json()).then((data) => {
      console.log('RECEIPT DATA:', data);
      setReceiptInfo(data)
    }).catch((error) => {
      return
      console.error('Error:', error);
    });
  }, [successfulPaymentInfo])

  function convertUnixTime(unixTime: number) {
    const date = new Date(unixTime * 1000);
    const options = { timeZone: 'America/Halifax' }
    return date.toLocaleString('en-US', options);
  }

  const handleSendEmail = () => {
    console.log("VISIT INFO: ", visitInfo)
    console.log("SUCCESSFUL PAYMENT INFO: ", successfulPaymentInfo)
    fetch(`http://localhost:8080/api/send_receipt_email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        license_plate: visitInfo?.license_plate,
        stripe_payment_intent_id: successfulPaymentInfo?.id,
        amount: successfulPaymentInfo?.payment_intent?.amount,
        description: successfulPaymentInfo?.description,
        status: successfulPaymentInfo?.status,
        visit_id: visitInfo?.visit.ID,
      }),
    }).then((response) => {
      if (response.status !== 200) {
        alert('Email could not be sent')
      }
      return response.json();
    }).then((data) => {
      console.log('RECEIPT DATA:', data);
      alert('Email sent')
    }).catch((error) => {
      alert('Email could not be sent')
      console.error('Error:', error);
    });
  }

  return (
    <>
      <Card
        title={`Visit Receipt`}
        // extra={<a href="#">More</a>} 
        style={{ width: '100%', justifyContent: 'left', textAlign: 'left', marginBottom: '10px' }}
      >
        <div style={{ justifyContent: 'left', textAlign: 'left', }}>
          {receiptInfo && (
            <>
              <p>{`Visit description: ${receiptInfo?.visit_data?.payment_intent?.description}`}</p>
              <p>{`License Plate: ${receiptInfo?.visit_data?.license_plate}`}</p>
              <p>{`Amount: ${parseInt(receiptInfo?.visit_data?.payment_intent?.amount)/100}`}</p>
              <p>{`Entry time: ${convertUnixTime(receiptInfo?.visit_data?.entry_time)}`}</p>
            </>
          )}
        </div>
      </Card>
        <Space direction="vertical" style={{ width: '100%', }}>
          <Input placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email} />
          <Button type="primary" onClick={() => handleSendEmail()} style={{ width: '100%' }}>
            Send Receipt
          </Button>
          <Button onClick={() => handleExit()} style={{ width: '100%', }}>
            Exit
          </Button>
        </Space>
    </>
  );
};
