import {
  useTranslate,
  IResourceComponentsProps,
  useList,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
} from "@pankod/refine-antd";

import { formatDateTime } from "utils/dates";

import { HouseInvite } from "interfaces/houseinvite";
import { House } from "interfaces/house";


export const HouseInviteList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, sorter } = useTable<HouseInvite>({
    initialSorter: [
      {
        field: "name",
        order: "desc",
      },
    ],
  });

  const housesQueryResult = useList<House>({
    resource: "houses",
  });

  // Get House Address
  const getHouseAddress = (id: string): string => {
    if (housesQueryResult.data?.data && housesQueryResult.data.data.length > 0) {
      const data = housesQueryResult.data.data;
      for (let x: number = 0; x < data.length; x++) {
        var house: House = data[x];
        if (house.ID === id) {
          return house.Address;
        }
      }
    }
    return "";
  } 

  
  return (
    <List title={"Invite user to House"}>
      <Table {...tableProps} rowKey="id">
        {/* <Table.Column
          dataIndex="ID"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        /> */}
        <Table.Column
          dataIndex="PhoneNumber"
          key="PhoneNumber"
          title={"Phone Number"}
          render={(value) => <TextField value={value} />}
          // defaultSortOrder={getDefaultSortOrder("email", sorter)}
          // sorter
        />
        {/* <Table.Column
          dataIndex="HouseID"
          key="HouseID"
          title={"HouseID"}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("HouseID", sorter)}
          sorter
        /> */}
        <Table.Column
            dataIndex="HouseID"
            key="HouseID"
            title={"House Address"}
            render={(value) => <TextField value={getHouseAddress(value)} />}
            // defaultSortOrder={getDefaultSortOrder("houseid", sorter)}
            // sorter
          />
        {/* <Table.Column
          dataIndex="UpdatedAt"
          key="updatedAt"
          title={t("users.fields.updatedAt")}
          render={(value) => {
            const formattedDate = formatDateTime(value);
            return <TextField value={formattedDate} />;
          }}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
        /> */}
        <Table.Column
          dataIndex="CreatedAt"
          key="createdAt"
          title={t("users.fields.createdAt")}
          render={(value) => {
            const formattedDate = formatDateTime(value);
            return <TextField value={formattedDate} />;
          }}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column<HouseInvite>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {/* <EditButton hideText size="small" recordItemId={record.ID} /> */}
              {/* <ShowButton hideText size="small" recordItemId={record.ID} /> */}
              <DeleteButton hideText size="small" recordItemId={record.ID} />
            </Space>
          )}
        />
        
      </Table>
    </List>
  );
};
