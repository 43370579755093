import {
  useTranslate,
  IResourceComponentsProps,
  useList,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
} from "@pankod/refine-antd";

import { formatDateTime } from "utils/dates";


import { Location } from "interfaces/location";
import { Gate } from "interfaces/gate";

export const GateList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, sorter } = useTable<Gate>({
    initialSorter: [
      {
        field: "name",
        order: "desc",
      },
    ],
  });

  const locationsQueryResult = useList<Location>({
    resource: "locations",
  });

  const getLocationName = (id: string): string => {
    if (locationsQueryResult.data?.data && locationsQueryResult.data.data.length > 0) {
      const data = locationsQueryResult.data.data;
      for (let x: number = 0; x < data.length; x++) {
        var loc: Location = data[x];
        if (loc.ID === id) {
          return loc.Name;
        }
      }
    }
    return "";
  }

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="ID"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="Name"
          key="Name"
          title={"Name"}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("Name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="Url"
          key="Url"
          title={"Gate URL"}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("Url", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="IpV6"
          key="IpV6"
          title={"Gate IP V6"}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("IpV6", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="LastSeen"
          key="LastSeen"
          title={"Last Seen"}
          render={(value) => {
            const formattedDate = formatDateTime(value);
            return <TextField value={formattedDate} />;
          }}
          defaultSortOrder={getDefaultSortOrder("lastSeen", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="GateType"
          key="GateType"
          title={"Gate Type"}
          render={(value) => {
            return <TextField value={value} />;
          }}
          // defaultSortOrder={getDefaultSortOrder("lastSeen", sorter)}
          // sorter
        />
        <Table.Column
          dataIndex="LocationID"
          key="LocationID"
          title={t("Location ID")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("location", sorter)}
        />
        <Table.Column
          dataIndex="LocationID"
          key="LocationID"
          title={t("Location Name")}
          render={(value) => <TextField value={getLocationName(value)} />}
          defaultSortOrder={getDefaultSortOrder("location", sorter)}
        />
        <Table.Column
          dataIndex="UpdatedAt"
          key="updatedAt"
          title={t("users.fields.updatedAt")}
          render={(value) => {
            const formattedDate = formatDateTime(value);
            return <TextField value={formattedDate} />;
          }}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="CreatedAt"
          key="createdAt"
          title={t("users.fields.createdAt")}
          render={(value) => {
            const formattedDate = formatDateTime(value);
            return <TextField value={formattedDate} />;
          }}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column<Gate>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.ID} />
              {/* <ShowButton hideText size="small" recordItemId={record.ID} /> */}
              <DeleteButton hideText size="small" recordItemId={record.ID} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
