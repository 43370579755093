import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  useForm,
  Select,
  useSelect,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { User } from "interfaces/user";

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<User>();
  const t = useTranslate();

  const { selectProps: locationSelectProps } = useSelect<Location>({
    resource: "locations",
    optionLabel: "Name",
    optionValue: "ID"
  });


  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t("users.fields.name")}
          name="Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("users.fields.email")}
          name="Email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"Calling Code"}
          name="CallingCode"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"Phone Number"}
          name="PhoneNumber"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Is Admin?"
          name="IsAdmin"
          rules={[
              {
                  required: true,
              },
          ]}
        >
          <Select
            options={[
              {
                  label: "YES",
                  value: true,
              },
              {
                  label: "NO",
                  value: false,
              },
            ]}
          />
        </Form.Item>
        
        <Form.Item
          label="Location"
          name={"LocationID"}
        >
          <Select 
            {...locationSelectProps} 
            showSearch
            filterOption={(input, option) =>
              typeof option?.label === "string" && option?.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
