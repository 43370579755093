import React from "react";
import routerProvider from "@pankod/refine-react-router-v6";
import { TitleProps } from "@pankod/refine-core";

import {
  Show,
  Typography,
} from "@pankod/refine-antd";

import {
  useShow,
  useOne,
  IResourceComponentsProps,
  useTranslate,
} from "@pankod/refine-core";

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <Link to="/">
    {collapsed ? (
      <img
        src={"/unosec-logo.png"}
        alt="UnoSec"
        style={{
          width: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "12px 24px",
        }}
      />
    ) : (
      <img
        src={"/unosec-logo.png"}
        alt="UnoSec"
        style={{
          width: "200px",
          padding: "12px 24px",
        }}
      />
    )}
  </Link>
);