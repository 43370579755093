import {
  useTranslate,
  IResourceComponentsProps,
  useList
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
} from "@pankod/refine-antd";

import { formatDateTime } from "utils/dates";

import { GateOpening } from "interfaces/gateopening";
import { User } from "interfaces/user";
import { Gate } from "interfaces/gate";
import { House } from "interfaces/house";
import { Access } from "interfaces/access";

export const GateOpeningList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, sorter } = useTable<GateOpening>({
    initialSorter: [
      {
        field: "name",
        order: "desc",
      },
    ],
  });

  // const getLocationName = (id: string): string => {
  //   if (locationsQueryResult.data?.data && locationsQueryResult.data.data.length > 0) {
  //     const data = locationsQueryResult.data.data;
  //     for (let x: number = 0; x < data.length; x++) {
  //       var loc: Location = data[x];
  //       if (loc.ID === id) {
  //         return loc.Name;
  //       }
  //     }
  //   }
  //   return "";
  // }


  const usersQueryResult = useList<User>({
    resource: "users",
  });

  const gateQueryResult = useList<Gate>({
    resource: "gates",
  });

  const houseQueryResult = useList<House>({
    resource: "houses",
  });

  const accessQueryResult = useList<Access>({
    resource: "accesses",
  });

  const getUser = (id: string): string => {
    if (usersQueryResult.data?.data && usersQueryResult.data.data.length > 0) {
      const data = usersQueryResult.data.data;
      for (let x: number = 0; x < data.length; x++) {
        var user: User = data[x];
        if (user.ID === id) {
          return user.Name;
        }
      }
    }
    return "";
  }

  const getGateName = (id: string): string => {
    if (gateQueryResult.data?.data && gateQueryResult.data.data.length > 0) {
      const data = gateQueryResult.data.data;
      for (let x: number = 0; x < data.length; x++) {
        var gate: Gate = data[x];
        if (gate.ID === id) {
          return gate.Name;
        }
      }
    }
    return "";
  }

  const getHouseAddress = (id: string): string => {
    // find house address, we have the access id in gateopening
    if (accessQueryResult.data?.data && accessQueryResult.data.data.length > 0) {
      const data = accessQueryResult.data.data;
      for (let x: number = 0; x < data.length; x++) {
        var access: Access = data[x];
        if (access.ID === id) {
          if (houseQueryResult.data?.data && houseQueryResult.data.data.length > 0) {
            const houseData = houseQueryResult.data.data;
            for (let y: number = 0; y < houseData.length; y++) {
              var house: House = houseData[y];
              if (house.ID === access.house_id) {
                return house.Address;
              }
            }
          }
        }
      }
    }
    
    return "";
  }


  return (
    <List title={"Gate Openings"}>
      <Table {...tableProps} rowKey="id">
        {/* <Table.Column
          dataIndex="ID"
          key="ID"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        /> */}
        {/* <Table.Column
          dataIndex="Handle"
          key="Handle"
          title="Handle"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        /> */}
        <Table.Column
          dataIndex="GateID"
          key="GateID"
          title="Gate Name"
          render={(value) => <TextField value={getGateName(value)} />}
          defaultSortOrder={getDefaultSortOrder("GateID", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="UserID"
          key="UserID"
          title={t("User Name")}
          render={(value) => <TextField value={getUser(value)} />}
          defaultSortOrder={getDefaultSortOrder("user", sorter)}
        />
        <Table.Column
          dataIndex="Plate"
          key="Plate"
          title="Plate"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="ImagePath"
          key="ImagePath"
          title="ImagePath"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        {/* <Table.Column
          dataIndex="AccessID"
          key="AccessID"
          title="AccessID"
          render={(value) => <TextField value={value} />}
        /> */}
        <Table.Column
          dataIndex="AccessID"
          key="AccessID"
          title="House Address"
          render={(value) => <TextField value={getHouseAddress(value)} />}
        />
        <Table.Column
          dataIndex="UpdatedAt"
          key="UpdatedAt"
          title={t("users.fields.updatedAt")}
          render={(value) => {
            const formattedDate = formatDateTime(value);
            return <TextField value={formattedDate} />;
          }}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="CreatedAt"
          key="CreatedAt"
          title={t("users.fields.createdAt")}
          render={(value) => {
            const formattedDate = formatDateTime(value);
            return <TextField value={formattedDate} />;
          }}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
      </Table>
    </List>
  );
};
