import React, {useState} from 'react';
import { Breadcrumb, Layout, Menu, Button, message, Steps, Input, Form } from 'antd';
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { SearchLicenseForm } from "../../components/stripe/SearchLicenseForm";
import { StripeElementContainer } from 'components/stripe/StripeElementContainer';
import { ReceiptCard } from 'components/stripe/ReceiptCard';
const { Header, Content, Footer } = Layout;

const stripePromise = loadStripe('pk_test_51P3QWRP2SKh432jt0bWzjNcmESkiAmLQSlnWGTzngTFbQxQceJcpcLkasXRermUrraDhLOLyz6AB404dHiRC848R00h8Npfweg');

export const PaymentsDashboard: React.FC = () => {
  // const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const [visitInfo, setVisitInfo] = useState<any>({});
  const [successfulPaymentInfo, setSuccessfulPaymentInfo] = useState<any>(null);
  

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };  
  
  const contentStyle: React.CSSProperties = {
    // lineHeight: '260px',
    textAlign: 'center',
    // color: token.colorTextTertiary,
    // backgroundColor: token.colorFillAlter,
    // borderRadius: token.borderRadiusLG,
    // border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  console.log('visitInfo:', visitInfo);
  
  const steps = [
    {
      title: 'Search your license plate',
      content: (
        <SearchLicenseForm 
          visitInfo={visitInfo} 
          setVisitInfo={setVisitInfo}
          next={next}
          />
      ),
    },
    {
      title: 'Pay for your visit',
      content: (<StripeElementContainer 
        stripePromise={stripePromise} 
        visitInfo={visitInfo} 
        setCurrent={setCurrent}
        prev={prev}
        setSuccessfulPaymentInfo={setSuccessfulPaymentInfo}
        />
      ),
    },
    {
      title: 'Receipt',
      content: (<ReceiptCard 
        successfulPaymentInfo={successfulPaymentInfo} 
        visitInfo={visitInfo}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <Layout style={{minHeight: '100vh', backgroundColor: '#fff', display: 'flex', flexDirection: 'column'}}>
      <Header style={{ display: 'flex', alignItems: 'center' }}>
        <div className="demo-logo" />
      </Header>
      <Content style={{ 
        flex: '1 0 auto',
        padding: '0 48px',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <div
          style={{
            background: '#fff',
            minHeight: 280,
            padding: 24,
            borderRadius: 2,
            // display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <Steps current={current} items={items} />
            <div style={contentStyle}>
              {steps[current].content}
            </div>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center', flexShrink: 0 }}>
        UnoSec Inc. ©{new Date().getFullYear()} Created by UnoSec Inc.
      </Footer>
    </Layout>
  );
};
