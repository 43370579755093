import React from 'react';
import { Card, Space } from 'antd';

interface VisitBalanceProps {
    // Define the props for your component here
    visitInfo: any;
}

export const VisitBalance: React.FC<VisitBalanceProps> = ({visitInfo}) => {
    // Implement your component logic here

    return (
        <Card 
          title={`Balance: $${visitInfo.outstanding_amount}`} 
          // extra={<a href="#">More</a>} 
          style={{ width: '100%', justifyContent: 'left', textAlign: 'left', marginBottom: '20px'}}
        >
          <div style={{justifyContent: 'left', textAlign: 'left', }}>
          <p>{`Total: $${visitInfo.total_amount}`}</p>
          <p>{`Tax: $${visitInfo.tax_charge}`}</p>
          <p>{`Parking fee per hour: $${visitInfo?.visit?.location.price_per_hour}`}</p>
          <p>{`Entry time: ${visitInfo.entry_time}`}</p>
          <p>{`Time in visit: ${visitInfo.total_visit_time_hours} hours`}</p>
          </div>
        </Card>
    );
};