import { useState } from "react";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";

import { Create, Form, Input, InputNumber, Select, useForm } from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css"

import { House } from "interfaces/house";
import { counties } from './counties';

const { Option } = Select;

export const LocationCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { formProps, saveButtonProps } = useForm<House>();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t("Name")}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item 
          label="Select County"
          name={"County"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            {counties.map((county) => (
              <Option key={county} value={county}>
                {county}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("Location Permanent Access Limit")}
          name="location_permanent_access_limit"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("Location Temporary Access Limit")}
          name="location_temporary_access_limit"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("Location Temporary Time Limit")}
          name="location_temporary_time_limit"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Can Open Gate?"
          name="CanOpenGate"
          rules={[
              {
                  required: true,
              },
          ]}
        >
          <Select
            options={[
              {
                  label: "YES",
                  value: true,
              },
              {
                  label: "NO",
                  value: false,
              },
            ]}
          />
        </Form.Item>
        {/* <Form.Item
          label="Is Parking?"
          name="IsParking"
          rules={[
              {
                  required: true,
              },
          ]}
        >
          <Select
            options={[
              {
                  label: "YES",
                  value: true,
              },
              {
                  label: "NO",
                  value: false,
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label={"Price Per Hour"}
          name="price_per_hour"
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]} 
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label={"Fraction Price"}
          name="fraction_price"
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Total Spots"
          name={"total_spots"}
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
            <InputNumber />
        </Form.Item>
        <Form.Item
          label="Available Spots"
          name={"available_spots"}
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
            <InputNumber />
        </Form.Item> */}
      </Form>
    </Create>
  );
};
