import React, { useState } from 'react';
import { Alert, Button, Form, Input } from 'antd';
import { VisitBalance } from './VisitBalance';
import { set } from 'video.js/dist/types/tech/middleware';

interface SearchLicenseFormProps {
  visitInfo: any;
  setVisitInfo: (visitInfo: any) => void;
  next: () => void;
}

export const SearchLicenseForm: React.FC<SearchLicenseFormProps> = ({
  visitInfo,
  setVisitInfo,
  next
}) => {
  const [plate, setPlate] = useState<string>('');
  const [showBalance, setShowBalance] = useState<boolean>(false);
  const [showResponseMessage, setShowResponseMessage] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [disableNextButton, setDisableNextButton] = useState<boolean>(false);

  const [responseType, setResponseType] = useState<string>('');

  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    console.log(e, 'I was closed.');
  };

  const handleSearchButton = () => {
    fetch(`http://localhost:8080/api/get_visit_by_license_plate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        license_plate: plate, 
        // location_id: visitInfo.visit.location.ID
        location_id: 2
      }),
    })
    .then((response) => {
      if (response.status === 404) {
        setShowResponseMessage(true);
        setResponseType('error');
        setResponseMessage('License plate not found');
        setShowBalance(false);
        return;
      }
      if (!response.ok) {
        setShowResponseMessage(true);
        setResponseType('error');
        setResponseMessage('Something went wrong');
        setShowBalance(false);
        return;
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        console.log('DATA ON SEARCH LICENSE PLATE:', data);
        if (data.message.type === "payment_expired") {
          setShowResponseMessage(true);
          setResponseType('warning');
          setResponseMessage(data.message.message);
          setShowBalance(true);
          setVisitInfo(data);
          setDisableNextButton(true);
          return;
        }
        if (data.message.type === "already_paid") {
          setShowResponseMessage(true);
          setResponseType('warning');
          setResponseMessage(data.message.message);
          setShowBalance(true);
          setVisitInfo(data);
          return;
        }
        setVisitInfo(data);
        setShowBalance(true);
        setShowResponseMessage(false);
        setDisableNextButton(true);
      }
      // next();
    })
    .catch((error) => {
      console.error('Error:', error);
      setShowResponseMessage(true);
      setResponseType('error');
      setResponseMessage('Something went wrong');
      setShowBalance(false);
    });
  }

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase().trim();
    setPlate(value);
  }

    return (
      <>
        <Form>
          <Form.Item>
            <Input 
              placeholder="Search license plate" 
              onChange={(e) => handleInputOnChange(e)}
              value={plate}
            />
          </Form.Item>
          <Button type="primary" onClick={() => handleSearchButton()} style={{width: '100%'}}>
            Search
          </Button>
        </Form>
        <div style={{marginTop: '20px'}}>
        {
          showBalance && <VisitBalance visitInfo={visitInfo} />
        }
        {
          showResponseMessage && <Alert message={responseMessage} type={responseType as "success" | "info" | "warning" | "error" | undefined} closable onClose={onClose} style={{ marginBottom: '20px' }}/>
        }
        <Button type="primary" onClick={() => next()} disabled={!disableNextButton} style={{width: '100%',}}>
          Next
        </Button>
        </div>
      </>
    );
};
