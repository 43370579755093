import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  CardElement
} from "@stripe/react-stripe-js";
import "./Stripe.css";
import { Button, Alert, Space } from "antd";
import { CloseSquareFilled } from '@ant-design/icons';

interface StripeFormProps {
  clientSecret: string;
  setCurrent: (current: number) => void;
  visitInfo: any;
  prev: () => void;
  setSuccessfulPaymentInfo: (successfulPaymentInfo: any) => void;
}

export const StripeForm: React.FC<StripeFormProps> = ({ clientSecret, setCurrent, visitInfo, prev, setSuccessfulPaymentInfo }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);



  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // setSuccessfulPaymentInfo(paymentIntent);
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        case "requires_confirmation":
          // setMessage("Your payment requires confirmation.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://localhost:3000/payments",
      },
      redirect: "if_required"
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      console.log("Error in confirm payment: ", error);
      setMessage(error.message ?? "");
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      // Payment was successful
      console.log("PAYMENT INTENT: ", paymentIntent)
      setMessage("Payment successful!");
      setSuccessfulPaymentInfo(paymentIntent);
      setCurrent(2);
    } else {
      setMessage("Something went wrong.");
    }

    // setIsLoading(false);
  };

  const paymentElementOptions: any = {
    layout: "tabs"
  }

  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    console.log('I was closed.');
  };

  return (
    <>
      <div id="payment-body">
        <form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement id="payment-element" options={paymentElementOptions} />
          <Button id="pay-button" type="primary" htmlType="submit" disabled={isLoading || !stripe || !elements}>
            <span id="button-text">
              {isLoading ? <div className="spinner" id="spinner"></div> : `Pay now $${visitInfo.outstanding_amount}`}
            </span>
          </Button>
          {message && <Alert
            message="Error"
            description={message}
            type="error"
            closable
            onClose={onClose}
            style={{ marginTop: '10px' }}
          />}

        </form>
      </div>
      <Button style={{ width: '100%', marginTop: '20px' }} onClick={() => prev()}>
        Previous
      </Button>
    </>
  );
}