import {
  useTranslate,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
} from "@pankod/refine-antd";

import { formatDateTime } from "utils/dates";

import { User } from "interfaces/user";

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, sorter } = useTable<User>({
    initialSorter: [
      {
        field: "name",
        order: "desc",
      },
    ],
  });

  const token = localStorage.getItem("refine-auth");
    let initialIsSuperuser = false;

    if (token) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      
      initialIsSuperuser = payload.is_superuser;
    }

  
  return (
    <List canCreate={initialIsSuperuser ? true : false}>
      <Table {...tableProps} rowKey="id">
        {/* <Table.Column
          dataIndex="ID"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        /> */}
        <Table.Column
          dataIndex="Name"
          key="name"
          title={t("users.fields.name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="Email"
          key="email"
          title={t("users.fields.email")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("email", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="CallingCode"
          key="CallingCode"
          title={"Calling Code"}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="PhoneNumber"
          key="PhoneNumber"
          title={"Phone Number"}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("phone", sorter)}
          sorter
        />
        {/* <Table.Column
          dataIndex="Superuser"
          key="superuser"
          title={"Superuser?"}
          render={(value) => <TextField value={value ? "True" : "False"} />}
          defaultSortOrder={getDefaultSortOrder("super_user", sorter)}
          sorter
        /> */}
        <Table.Column
          dataIndex="IsDevice"
          key="isdevice"
          title={"Is Device?"}
          render={(value) => <TextField value={value ? "True" : "False"} />}
        />
        <Table.Column
          dataIndex="IsAdmin"
          key="isadmin"
          title={"Is Admin?"}
          render={(value) => <TextField value={value ? "True" : "False"} />}
        />
        <Table.Column
          dataIndex="IsConfirmed"
          key="IsConfirmed"
          title={"Is Confirmed?"}
          render={(value) => <TextField value={value ? "True" : "False"} />}
        />
        <Table.Column
          dataIndex="UpdatedAt"
          key="updatedAt"
          title={t("users.fields.updatedAt")}
          render={(value) => {
            const formattedDate = formatDateTime(value);
            return <TextField value={formattedDate} />;
          }}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="CreatedAt"
          key="createdAt"
          title={t("users.fields.createdAt")}
          render={(value) => {
            const formattedDate = formatDateTime(value);
            return <TextField value={formattedDate} />;
          }}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        {initialIsSuperuser && (
        <Table.Column<User>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.ID} />
              {/* <ShowButton hideText size="small" recordItemId={record.ID} /> */}
              <DeleteButton hideText size="small" recordItemId={record.ID} />
            </Space>
          )}
        />
        )}
      </Table>
    </List>
  );
};
