import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  Select,
  useForm,
  useSelect
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { Gate } from "interfaces/gate";
import { Location } from "interfaces/location"


export const GateEdit: React.FC<IResourceComponentsProps> = () => {

  const { formProps, saveButtonProps, queryResult } = useForm<Gate>();
  const t = useTranslate();
  const { selectProps: locationSelectProps } = useSelect<Location>({
    resource: "locations",
    optionLabel: "Name",
    optionValue: "ID"
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t("Name")}
          name="Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("Url")}
          name="Url"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("IpV6")}
          name="IpV6"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Location"
          name={"LocationID"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select 
            {...locationSelectProps} 
            showSearch
            filterOption={(input, option) =>
              typeof option?.label === "string" && option?.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label="Gate Type"
          name={"GateType"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Select.Option value="entrance">Entrance</Select.Option>
            <Select.Option value="exit">Exit</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Edit>
  );
};
