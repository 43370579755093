import {
    useTranslate,
    IResourceComponentsProps,
    useShow,
  } from "@pankod/refine-core";
  import { Show, Typography } from "@pankod/refine-antd";
  
  import { UserHouse } from "interfaces/userhouse";
  
  const { Title, Text } = Typography;
  
  export const UserHouseShow: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
  
    const { queryResult } = useShow<UserHouse>();
    const { data, isLoading } = queryResult;
    const record = data?.data;
  
    return (
      <Show isLoading={isLoading}>
        {/* <Title level={5}>{t("users.fields.name")}</Title>
        <Text>{record?.Name}</Text>
  
        <Title level={5}>{t("users.fields.email")}</Title>
        <Text>{record?.Email}</Text>
  
        <Title level={5}>{t("users.fields.superuser")}</Title>
        <Text>{record?.Superuser}</Text>
  
        <Title level={5}>{t("users.fields.createdAt")}</Title>
        <Text>{record?.CreatedAt}</Text>
        
        <Title level={5}>{t("users.fields.updatedAt")}</Title>
        <Text>{record?.UpdatedAt}</Text> */}
  
      </Show>
    );
  };