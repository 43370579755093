import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  Select,
  useForm,
  useSelect
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { House } from "interfaces/house";
import { Location } from "interfaces/location"
import { User } from "interfaces/user";

export const HouseEdit: React.FC<IResourceComponentsProps> = () => {

  const { formProps, saveButtonProps, queryResult } = useForm<House>();
  const t = useTranslate();
  const { selectProps: locationSelectProps } = useSelect<Location>({
    resource: "locations",
    optionLabel: "Name",
    optionValue: "ID"
  });

  const { selectProps: userSelectProps } = useSelect<User>({
    resource: "users",
    optionLabel: "Email",
    optionValue: "ID"
  });
  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t("Address")}
          name="Address"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Location"
          name={"LocationID"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select 
            {...locationSelectProps} 
            showSearch
            filterOption={(input, option) =>
              typeof option?.label === "string" && option?.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label="User"
          name={"UserID"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select 
            {...userSelectProps} 
            showSearch
            filterOption={(input, option) =>
              typeof option?.label === "string" && option?.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
