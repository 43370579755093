import { useState } from "react";
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Checkbox,
  Edit,
  Form,
  Input,
  Select,
  useForm,
  useSelect
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { UserHouse } from "interfaces/userhouse";
import { User } from "interfaces/user";
import { House } from "interfaces/house";

export const UserHouseEdit: React.FC<IResourceComponentsProps> = () => {

  const { formProps, saveButtonProps, queryResult } = useForm<UserHouse>();

  const { selectProps: userSelectProps } = useSelect<User>({
    resource: "users",
    optionLabel: "Name",
    optionValue: "ID"
  });

  const { selectProps: houseSelectProps } = useSelect<House>({
    resource: "houses",
    optionLabel: "Address",
    optionValue: "ID"
  });

  const [userHouse, setUserHouse] = useState<UserHouse>({
    ID: "",
    CreatedAt: "",
    UpdatedAt: "",
    DeletedAt: undefined,
    UserID: "",
    HouseID: "",
    HouseOwner: false
  });
  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
      <Form.Item
          label="User"
          name={"user_id"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...userSelectProps} />
        </Form.Item>
        <Form.Item
          label="House"
          name={"house_id"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...houseSelectProps} />
        </Form.Item>
        <Form.Item
            label="House Owner"
            name={"house_owner"}
            valuePropName="checked"  
          >
            <Checkbox checked={userHouse.HouseOwner} onChange={(e) => setUserHouse({ ...userHouse, HouseOwner: e.target.checked })}>
              Is owner?
            </Checkbox>
        </Form.Item>
      </Form>
    </Edit>
  );
};
