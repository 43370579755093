import { IResourceComponentsProps } from "@pankod/refine-core";
import axios from "axios";
import { API_URL } from "const";
import { TOKEN_KEY }  from "../../providers/authProvider";
import {
  Create,
  Checkbox,
  Form,
  Input,
  useForm,
  useSelect,
  Select,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";
import { House } from "interfaces/house";
import { UserHouse } from "interfaces/userhouse";
import { notification } from "antd";

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const UserHouseCreate: React.FC<IResourceComponentsProps> = () => {
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  const { formProps, saveButtonProps, redirect } = useForm<UserHouse>();
    const { selectProps: houseSelectProps } = useSelect<House>({
      resource: "houses",
      optionLabel: "Address",
      optionValue: "ID"
    });

  const createNewUserHouse = (values: any) => {
    const token = localStorage.getItem(TOKEN_KEY);
    axios.post(`${API_URL}/new_user_house`, {
      calling_code: values.CallingCode,
      phone_number: values.PhoneNumber,
      house_id: values.house_id,
      house_owner: values.house_owner
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(req => {
      if (req.status === 200 || req.status === 201) {
        redirect("list");
      }
    })
    .catch(error => {
      openNotificationWithIcon("error", "Error", "Failed to create user house or user already exists in house.");
    });
  }

  return (
    <>
    {contextHolder}
    <Create saveButtonProps={saveButtonProps} title="Add user to house">
      
      <Form {...formProps} layout="vertical" onFinish={createNewUserHouse}>
      <Form.Item
          label="Calling Code"
          name={"CallingCode"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      <Form.Item
          label="Phone Number"
          name={"PhoneNumber"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item> 
        <Form.Item
          label="House"
          name={"house_id"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...houseSelectProps} />
        </Form.Item>
        <Form.Item
            label="House Owner"
            name={"house_owner"}
            valuePropName="checked"  
          >
            <Checkbox onChange={(e) =>  e.target.checked}>
              Is owner?
            </Checkbox>
        </Form.Item>
      </Form>
    </Create>
    </>
  );
};
