import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  useForm,
  useSelect,
  Select
} from "@pankod/refine-antd";
import "react-mde/lib/styles/css/react-mde-all.css";
import { Location } from "interfaces/location";
import { UserLocation } from "interfaces/userlocations";
import { User } from "interfaces/user";


export const UserLocationEdit: React.FC<IResourceComponentsProps> = () => {

  const { formProps, saveButtonProps } = useForm<UserLocation>();

  const { selectProps: locationSelectProps } = useSelect<Location>({
    resource: "locations",
    optionLabel: "Name",
    optionValue: "ID"
  });

  const { selectProps: userSelectProps } = useSelect<User>({
    resource: "users",
    optionLabel: "Name",
    optionValue: "ID"
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
      <Form.Item
          label="User"
          name={"ID"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...userSelectProps} />
        </Form.Item>
        <Form.Item
          label="Location"
          name={"LocationID"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...locationSelectProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};