import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  useForm,
  useSelect,
  Select
} from "@pankod/refine-antd";
import "react-mde/lib/styles/css/react-mde-all.css";
import { House } from "interfaces/house";
import { HouseInvite } from "interfaces/houseinvite";


export const HouseInviteCreate: React.FC<IResourceComponentsProps> = () => {

  const { formProps, saveButtonProps } = useForm<HouseInvite>();

  const { selectProps: houseSelectProps } = useSelect<House>({
    resource: "houses",
    optionLabel: "Address",
    optionValue: "ID"
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
      <Form.Item
          label="Calling Code"
          name={"CallingCode"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      <Form.Item
          label="Phone Number"
          name={"PhoneNumber"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="House"
          name={"HouseID"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...houseSelectProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};
