import {
    useTranslate,
    IResourceComponentsProps,
  } from "@pankod/refine-core";
  import {
    List,
    Table,
    TextField,
    useTable,
    getDefaultSortOrder,
    DateField,
    Space,
    EditButton,
    DeleteButton,
    ShowButton,
  } from "@pankod/refine-antd";
  import { UserLocation } from "interfaces/userlocations";
  
  
  export const UserLocationList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
  
    const { tableProps, sorter } = useTable<UserLocation>({
      initialSorter: [
        {
          field: "name",
          order: "desc",
        },
      ],
    });
  
    
    return (
      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="ID"
            key="id"
            title="ID"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("id", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="locationID"
            key="locationID"
            title={"Location ID"}
            render={(value) => <TextField value={value} />}
            // defaultSortOrder={getDefaultSortOrder("email", sorter)}
            // sorter
          />
          <Table.Column
            dataIndex="userID"
            key="userID"
            title={"User ID"}
            render={(value) => <TextField value={value} />}
            // defaultSortOrder={getDefaultSortOrder("HouseID", sorter)}
            // sorter
          />
          <Table.Column<UserLocation>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.ID} />
                {/* <ShowButton hideText size="small" recordItemId={record.ID} /> */}
                <DeleteButton hideText size="small" recordItemId={record.ID} />
              </Space>
            )}
          />
        </Table>
      </List>
    );
  };
  