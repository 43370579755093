import { useState } from "react";
import moment from "moment";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { DatePicker } from 'antd';
import { Create, Input, Select, useSelect, useForm } from "@pankod/refine-antd";
import { Form } from "antd";

import "react-mde/lib/styles/css/react-mde-all.css"

import { House } from "interfaces/house";
import { Access } from "interfaces/access";

export const AccessCreate: React.FC<IResourceComponentsProps> = () => {

  const { formProps, saveButtonProps, onFinish } = useForm<Access>();

  const { selectProps: houseSelectProps } = useSelect<House>({
    resource: "houses",
    optionLabel: "Address",
    optionValue: "ID"
  });

  const [validUntil, setValidUntil] = useState<number>(0);
  const [validFrom, setValidFrom] = useState<number>(0);


  const handleSubmit = (values: any) => {
    const access: Access = {
      ID: undefined,
      CreatedAt:  undefined,
      UpdatedAt:  undefined,
      DeletedAt:  undefined,
      house: undefined,
      house_id: values.house_id,
      car_plate: values.car_plate,
      person_name: values.person_name,
      // valid_from: moment(values.valid_from).unix(),
      valid_from: validFrom,
      // valid_until: moment(values.valid_until).unix() || 0,
      valid_until: validUntil,
    }
    onFinish(access);  
  };

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" onFinish={handleSubmit}>
      {/* <Form {...formProps} layout="vertical"> */}
        <Form.Item
          label={"Car Plate"}
          name="car_plate"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"Person Name"}
          name="person_name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="House"
          name={"house_id"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select 
            {...houseSelectProps} 
            showSearch
            filterOption={(input, option) =>
              typeof option?.label === "string" && option?.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label={"Valid From"}
          name="valid_from"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker showTime onChange={(value) => setValidFrom(moment(value).unix())}/>
        </Form.Item>
        <Form.Item
          label={"Valid Until"}
          name="valid_until"
        >
          <DatePicker showTime onChange={(value) => setValidUntil(moment(value).unix())} />
        </Form.Item>
      </Form>
    </Create>
  );
};
